import { CLIENT_URL } from "./env"

export const PATH = {
  SERVICE: {
    RAID: `${CLIENT_URL}/raid`,
    REGISTER: "/auth/register",
    // <register>
    // ユーザーが初めてログインした後、追加情報を入力するページ
    // 既にユーザー登録が完了している場合は、メインページ（RAID）にリダイレクト
    EMAIL_VERIFY: "/auth/email_verify",
    // <email_verify>
    // メール確認プロセスを管理するページ
    // メールが確認済みの場合、メインページ（RAID）にリダイレクト
    PRIVACY: "/help/privacy",
    TERMS: "/help/terms",
  },
  Auth: {
    LOGIN: "/api/auth/login",
    LOGOUT: "/api/auth/logout",
    SIGNUP: "/api/auth/signup",
    // <signup>
    // 新規ユーザー登録（サインアップ）処理を開始・Auth0の新規登録画面を開く
  },
} as const
